//TODO: Cannot inject socketIO, to get its event object, because socketIO depends on sq.user.auth.tokens
//To fix, can break up this module.
const bufferType = '50*';
const eventPrefix = 'sq.serverEvents.serverError.interceptor:';

angular.module('sq.serverEvents.serverError.interceptor',
['sq.common.httpInterceptorBuffer', 'sq.user.auth.tokens'])
//to avoid circular dependency
.factory('serverError', function (userTokens, httpBuffer, $log, $rootScope) {
  return {
    eventPrefix : eventPrefix,
      /**
       * Call this function to indicate that authentication was successfull and trigger a
       * retry of all deferred requests.
       * @param data an optional argument to pass on to $broadcast which may be useful for
       * example if you need to pass through details of the user that was logged in
       * @param configUpdater an optional transformation function that can modify the
       * requests that are retried after having logged in.  This can be used for example
       * to add an authentication token.  It must return the request.
       */
    retryAll: function(data, configUpdater) {
      const updater = configUpdater || function(config) {
        config.headers.Authorization = 'Bearer ' +  userTokens.idToken;
        return config;
      };
      $log.debug('retrying all failed 502 and <0 responses');

      $rootScope.$broadcast(eventPrefix + 'serverConfirmedUp');
      httpBuffer.retryAll(updater);
    },

      /**
       * Call this function to indicate that authentication should not proceed.
       * All deferred requests will be abandoned or rejected (if reason is provided).
       * @param data an optional argument to pass on to $broadcast.
       * @param reason if provided, the requests are rejected; abandoned otherwise.
       */
    cancelAll: function() {
      httpBuffer.rejectAll(new Error('server down permanently') );
      $rootScope.$broadcast(eventPrefix + 'serverDownPermanently');
    }
  };
})
  /**
   * $http interceptor.
   * On 401 response (without 'ignoreAuthModule' option) stores the request
   * and broadcasts 'event:auth-loginRequired'.
   * On 403 response (without 'ignoreAuthModule' option) discards the request
   * and broadcasts 'event:auth-forbidden'.
   */
  // TODO: use timeouts to make sure we don't send too many notices
.config(function(/*@ngInject*/$httpProvider) {
  let responseError = false;

  $httpProvider.interceptors.push(function(SETTINGS, $rootScope, $injector, $q, httpBuffer, $window, $log) {
    function notifyDown(event, permanent) {
      if(!responseError || permanent) {
        console.info('event', event);
        $log.debug('notified that server is down due to ' + event);
        $rootScope.$broadcast(eventPrefix + 'serverDown', permanent);
        responseError = true;
      }
    }

    function notifyUp(event) {
      if(responseError) {
        $log.debug('notified that server is up due to ' + event);
        $rootScope.$broadcast(eventPrefix + 'serverUp');
        responseError = false;
      }
    }
    // permanent failure of socket.io, take the application offline
    $rootScope.$on('sq.services.socketIO:reconnect_failed', (ev) => notifyDown(ev.name, true));

    // This gives too many false positives; trigerred if we go offline then quickly online
    // TODO: use timeout to prevent this scenario, maybe set to a second or two;
    // this implementation results in digest in progrss errors
    // let reconnectTimeout;
    // $rootScope.$on('sq.services.socketIO:reconnect_attempt', (ev) => {
    //   if(reconnectTimeout) {
    //     $timeout.cancel(reconnectTimeout);
    //   }

    //   reconnectTimeout = $timeout(() => {
    //     notifyDown(ev.name);
    //     reconnectTimeout = false;
    //   }, 1000, false);
    // });

    $rootScope.$on('sq.services.socketIO:connect', (ev) => notifyUp(ev.name));

    $window.addEventListener('offline', () => notifyDown('offline'));

    // Not reliable; does not mean we actually have a connection in chrome/safari
    // TODO: issue a test/ping request to see if server truly accessible after "online"
    // event triggered
    $window.addEventListener('online', () => {
      if(responseError) {
        const $http = $injector.get('$http');
        $http.get(SETTINGS.assetsUrl + 'ping', () => notifyUp('online'));
      }
    });
    //var thisBuffer = httpBuffer.getInstance(bufferType);
    //var thisBuffer = httpBuffer;
    return {
      //this doesn't work, because route changes that result in template rendering
      //trigger a status 200 response
      //we could limit to certain routes, but i'm not convinced it's worth it
      //can use socket.io to monitor instead
      response: function(response) {
        if (responseError && $window.navigator.onLine) {
          notifyUp('ok response');
        } else if(!responseError && !$window.navigator.onLine) {
          // angular templates will be served even when server down, so notify of
          // loss of general connectivity
          notifyDown('ok response, but offline');
        }

        return response;
      },
      responseError: function(rejection) {
        var config = rejection.config || {};

        // No matter the target; if our app is offline, notify the rest of the app
        if(!$window.navigator.onLine) {
          notifyDown('offLine');
        }

        // We will rety only a few kinds of events.
        // With our current nginx setup, 502 is returned whenever the
        // server has had an exception
        // and although $window.
        if(!config.noRetryOnServerError) {
          if (responseError || rejection.status === 502 || (rejection.status <= 0 && !$window.navigator.onLine) ) {
            notifyDown('previous error, 502 response, or offLine');

            // Retry when the server is back online
            $log.debug('storing response promise in serverError interceptor, due to perceived connectivity issue', rejection);
            const deferred = httpBuffer.defer(config);
            return deferred.promise;
          }
        }

        // otherwise, default behaviour
        return $q.reject(rejection);
      }
    };
  });
});